import * as Sentry from '@sentry/browser';
import type { AnalyticsSource } from '../../types';

export interface SentryConfig extends Sentry.BrowserOptions {
  env: 'prod' | 'dev' | 'local';
}

export default {
  initialize: ({ dsn, env, sampleRate, tracesSampleRate, release }) => {
    Sentry.init({
      dsn,
      tunnel: `https://${
        env === 'prod' ? 'api.prod' : 'api-internal.dev'
      }.whoop.com/sentry-proxy-service/v1/exception/`,
      sampleRate,
      tracesSampleRate,
      release,
    });
  },
  trackError: (err: any) => {
    if ((window as any)?.ENV !== 'local') {
      Sentry.captureException(err);
    }
  },
} as AnalyticsSource<SentryConfig>;
