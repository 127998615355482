import type { AmplitudeProps } from './sources/amplitude';
import amplitude from './sources/amplitude';
import type { DataDogConfig } from './sources/datadog';
import datadog from './sources/datadog';
import experiment from './sources/experiment';
import type { GoogleTagManagerConfig } from './sources/gtm';
import gtm from './sources/gtm';
import type { SegmentProps } from './sources/segment';
import segment from './sources/segment';
import type { SentryConfig } from './sources/sentry';
import sentry from './sources/sentry';
import whoopUserEventTracker from './sources/whoop-analytics';
import type {
  AnalyticsContext,
  UserProfile,
  InitializeFunction,
  IdentifyFunction,
  EventFunction,
  AnalyticsSource,
  WhoopAnalytics,
  UserEventTrackerProps,
  RouteChangeTrackerProps,
  ItemContext,
  PurchaseContext,
} from './types';

interface WebAnalyticsProps {
  amplitude?: AmplitudeProps;
  datadog?: DataDogConfig;
  gtm?: GoogleTagManagerConfig;
  segment?: SegmentProps;
  sentry?: SentryConfig;
}

export default {
  initialize: ({
    amplitude: amplitudeProps,
    datadog: datadogProps,
    gtm: gtmProps,
    segment: segmentProps,
    sentry: sentryProps,
  }) => {
    if (amplitudeProps) {
      amplitude.initialize(amplitudeProps);
    }

    if (datadogProps) {
      datadog.initialize(datadogProps);
    }

    if (gtmProps) {
      gtm.initialize(gtmProps);
    }

    if (segmentProps) {
      segment.initialize(segmentProps);
    }

    if (sentryProps) {
      sentry.initialize(sentryProps);
    }
  },
  identify: (user) => {
    if (segment.identify) {
      segment.identify(user);
    }
  },
  trackAmplitudeEvent: (eventName, context) => {
    if (amplitude.trackEvent) {
      amplitude.trackEvent(eventName, context);
    }
  },
  setAmplitudeUserId: (userId) => {
    if (amplitude.setUserId) {
      amplitude.setUserId(userId);
    }
  },
  setAmplitudeUserProperty: (key, value) => {
    if (amplitude.setUserProperty) {
      amplitude.setUserProperty(key, value);
    }
  },
  unsetAmplitudeUserProperty: (key) => {
    if (amplitude.unsetUserProperty) {
      amplitude.unsetUserProperty(key);
    }
  },
  trackError: (err) => {
    if (sentry.trackError) {
      sentry.trackError(err);
    }
  },
  trackSegmentEvent: (eventName, context) => {
    if (segment.trackEvent) {
      segment.trackEvent(eventName, context);
    }
  },
  getVariant: (experimentName, filters) =>
    experiment.getVariant(experimentName, filters),
  getVariantByUserToken: (experimentName, userToken, filters) =>
    experiment.getVariantByUserToken(experimentName, userToken, filters),
  registerUserEventTracker: (props: UserEventTrackerProps) => {
    whoopUserEventTracker.registerUserEventTracker(props);
  },
  notifyRouteChange: (props: RouteChangeTrackerProps) => {
    whoopUserEventTracker.notifyRouteChange(props);
  },
  trackPageView: (context) => {
    gtm.trackPageView(context);
  },
  trackPurchase: (context) => {
    gtm.trackPurchase(context);
  },
  trackGoogleEvent: (eventName, context) => {
    gtm.trackGoogleEvent(eventName, context);
  },
  getDeviceId: () => {
    return amplitude?.getDeviceId?.();
  },
  getSessionId: () => {
    return amplitude?.getSessionId?.();
  },
  trackDatadogAction: (name) => {
    datadog.addAction(name);
  },
} as WhoopAnalytics<WebAnalyticsProps>;

// Type Exports
export type {
  AnalyticsContext,
  UserProfile,
  InitializeFunction,
  IdentifyFunction,
  EventFunction,
  AnalyticsSource,
  WhoopAnalytics,
  WebAnalyticsProps,
  SegmentProps,
  DataDogConfig,
  SentryConfig,
  AmplitudeProps,
  GoogleTagManagerConfig,
  ItemContext,
  PurchaseContext,
};
