let baseUrl: string | undefined;

// TODO: Move this logic to a shared API client library once it exists
export const getBaseUrl = () => {
  if (baseUrl) {
    return baseUrl;
  }

  const env = process.env.NEXT_PUBLIC_ENV;

  switch (env) {
    case 'prod':
      baseUrl = `https://api.prod.whoop.com`;
      return baseUrl;
    case 'dev':
      baseUrl = `https://api.dev.whoop.com`;
      return baseUrl;
    case 'local':
      baseUrl = `http://localhost:8080`;
      return baseUrl;
    default:
      throw new Error(
        `${env} is not a valid environment, must be 'dev', 'prod' or 'local'`,
      );
  }
};

export const makeGetRequest = async <T>(
  path: string,
  headers?: HeadersInit,
): Promise<T> => {
  const result = await fetch(`${getBaseUrl()}/${path}`, {
    method: 'GET',
    headers,
  });
  return (await result
    .clone()
    .json()
    .catch(() => result.text())) as T;
};

export const makePostRequest = async <T>(
  path: string,
  headers?: HeadersInit,
  body?: BodyInit,
): Promise<T> => {
  const result = await fetch(`${getBaseUrl()}/${path}`, {
    method: 'POST',
    headers,
    body,
  });
  return (await result
    .clone()
    .json()
    .catch(() => result.text())) as T;
};
