const zod = require('zod');

const envSchema = zod.object({
  NEXT_PUBLIC_ENV: zod.enum(['local', 'dev', 'prod']),
  NEXT_PUBLIC_ORDER_SERVICE_API: zod.string().url(),
  NEXT_PUBLIC_MSV2_API: zod.string().url(),
  NEXT_PUBLIC_GCS_API: zod.string().url(),
  NEXT_PUBLIC_RMA_SERVICE_API: zod.string().url(),
  NEXT_PUBLIC_WHOOP_API: zod.string().url(),
  NEXT_PUBLIC_STRIPE_KEY: zod.string(),
  NEXT_PUBLIC_JOIN_URL: zod.string().url(),
  NEXT_PUBLIC_UPGRADE_URL: zod.string().url(),
  NEXT_PUBLIC_TRY_URL: zod.string().url(),
  NEXT_PUBLIC_WHOOP_URL: zod.string().url(),
  NEXT_PUBLIC_APP_URL: zod.string().url(),
  NEXT_PUBLIC_ORDER_STATUS_URL: zod.string().url(),
  NEXT_PUBLIC_CONTENTFUL_GRAPHQL_BASE_URL: zod.string().url(),
  NEXT_PUBLIC_CONTENTFUL_DELIVERY_TOKEN: zod.string(),
  NEXT_PUBLIC_CONTENTFUL_SPACE_ID: zod.string(),
  NEXT_PUBLIC_CONTENTFUL_PREVIEW: zod.enum(['enabled', 'disabled']),
  NEXT_PUBLIC_PRODUCT_SERVICE_API: zod.string().url(),
  NEXT_PUBLIC_AMPLITUDE_API_KEY: zod.string(),
  NEXT_PUBLIC_SEGMENT_API_KEY: zod.string(),
  NEXT_PUBLIC_GTM_ID: zod.string(),
  NEXT_PUBLIC_TURNSTILE_SITE_KEY: zod.string(),
  NEXT_PUBLIC_SHOPIFY_BASE_URL: zod.string().url(),
  NEXT_PUBLIC_SHOPIFY_ACCESS_TOKEN: zod.string(),
  NEXT_PUBLIC_OKENDO_SUBSCRIBER_ID: zod.string(),
  NEXT_PUBLIC_DD_APP_ID: zod.string(),
  NEXT_PUBLIC_DD_CLIENT_TOKEN: zod.string(),
  NEXT_PUBLIC_E2E_URL: zod.string().url(),
  NEXT_PUBLIC_SENTRY_DSN: zod.string(),
  NEXT_PUBLIC_CACHE_SECRET: zod.string(),
  NEXT_PUBLIC_SMARTY_API_KEY: zod.string(),
  NEXT_PUBLIC_STRIPE_PAYMENT_REDIRECT_URL: zod.string().url(),
  NEXT_PUBLIC_JF_REPLATFORM_URL: zod.string().url(),
  NEXT_PUBLIC_JOIN_FLIPKART_URL: zod.string().url(),
});

const env = envSchema.parse({
  NEXT_PUBLIC_ENV: process.env.NEXT_PUBLIC_ENV,
  NEXT_PUBLIC_ORDER_SERVICE_API: process.env.NEXT_PUBLIC_ORDER_SERVICE_API,
  NEXT_PUBLIC_MSV2_API: process.env.NEXT_PUBLIC_MSV2_API,
  NEXT_PUBLIC_GCS_API: process.env.NEXT_PUBLIC_GCS_API,
  NEXT_PUBLIC_RMA_SERVICE_API: process.env.NEXT_PUBLIC_RMA_SERVICE_API,
  NEXT_PUBLIC_WHOOP_API: process.env.NEXT_PUBLIC_WHOOP_API,
  NEXT_PUBLIC_STRIPE_KEY: process.env.NEXT_PUBLIC_STRIPE_KEY,
  NEXT_PUBLIC_JOIN_URL: process.env.NEXT_PUBLIC_JOIN_URL,
  NEXT_PUBLIC_UPGRADE_URL: process.env.NEXT_PUBLIC_UPGRADE_URL,
  NEXT_PUBLIC_TRY_URL: process.env.NEXT_PUBLIC_TRY_URL,
  NEXT_PUBLIC_WHOOP_URL: process.env.NEXT_PUBLIC_WHOOP_URL,
  NEXT_PUBLIC_APP_URL: process.env.NEXT_PUBLIC_APP_URL,
  NEXT_PUBLIC_ORDER_STATUS_URL: process.env.NEXT_PUBLIC_ORDER_STATUS_URL,
  NEXT_PUBLIC_CONTENTFUL_GRAPHQL_BASE_URL:
    process.env.NEXT_PUBLIC_CONTENTFUL_GRAPHQL_BASE_URL,
  NEXT_PUBLIC_CONTENTFUL_DELIVERY_TOKEN:
    process.env.NEXT_PUBLIC_CONTENTFUL_DELIVERY_TOKEN,
  NEXT_PUBLIC_CONTENTFUL_SPACE_ID: process.env.NEXT_PUBLIC_CONTENTFUL_SPACE_ID,
  NEXT_PUBLIC_CONTENTFUL_PREVIEW: process.env.NEXT_PUBLIC_CONTENTFUL_PREVIEW,
  NEXT_PUBLIC_PRODUCT_SERVICE_API: process.env.NEXT_PUBLIC_PRODUCT_SERVICE_API,
  NEXT_PUBLIC_AMPLITUDE_API_KEY: process.env.NEXT_PUBLIC_AMPLITUDE_API_KEY,
  NEXT_PUBLIC_SEGMENT_API_KEY: process.env.NEXT_PUBLIC_SEGMENT_API_KEY,
  NEXT_PUBLIC_GTM_ID: process.env.NEXT_PUBLIC_GTM_ID,
  NEXT_PUBLIC_TURNSTILE_SITE_KEY: process.env.NEXT_PUBLIC_TURNSTILE_SITE_KEY,
  NEXT_PUBLIC_SHOPIFY_BASE_URL: process.env.NEXT_PUBLIC_SHOPIFY_BASE_URL,
  NEXT_PUBLIC_SHOPIFY_ACCESS_TOKEN:
    process.env.NEXT_PUBLIC_SHOPIFY_ACCESS_TOKEN,
  NEXT_PUBLIC_OKENDO_SUBSCRIBER_ID:
    process.env.NEXT_PUBLIC_OKENDO_SUBSCRIBER_ID,
  NEXT_PUBLIC_DD_APP_ID: process.env.NEXT_PUBLIC_DD_APP_ID,
  NEXT_PUBLIC_DD_CLIENT_TOKEN: process.env.NEXT_PUBLIC_DD_CLIENT_TOKEN,
  NEXT_PUBLIC_E2E_URL: process.env.NEXT_PUBLIC_E2E_URL,
  NEXT_PUBLIC_SENTRY_DSN: process.env.NEXT_PUBLIC_SENTRY_DSN,
  NEXT_PUBLIC_CACHE_SECRET: process.env.NEXT_PUBLIC_CACHE_SECRET,
  NEXT_PUBLIC_SMARTY_API_KEY: process.env.NEXT_PUBLIC_SMARTY_API_KEY,
  NEXT_PUBLIC_STRIPE_PAYMENT_REDIRECT_URL:
    process.env.NEXT_PUBLIC_STRIPE_PAYMENT_REDIRECT_URL,
  NEXT_PUBLIC_JF_REPLATFORM_URL: process.env.NEXT_PUBLIC_JF_REPLATFORM_URL,
  NEXT_PUBLIC_JOIN_FLIPKART_URL: process.env.NEXT_PUBLIC_JOIN_FLIPKART_URL,
});

module.exports = { env };
