import type { GoogleSource } from '../../types';
import { isDatadogSyntheticTest } from '../datadog';
import initGoogleTagManager from './initGoogleTagManager.script';

export interface GoogleTagManagerConfig {
  id: string;
}

// Error only exists on build
// @ts-expect-error gtag does exist and is set in globals.d.ts
const isInitialized = (): boolean => window.gtag && !isDatadogSyntheticTest();

export default {
  initialize: (props) => {
    initGoogleTagManager(props.id);
  },
  trackPageView: (props) => {
    if (!isInitialized()) return;
    // @ts-expect-error gtag does exist and is set in globals.d.ts
    window.gtag('event', 'page_view', props);
  },
  trackPurchase: (props) => {
    if (!isInitialized()) return;
    // @ts-expect-error gtag does exist and is set in globals.d.ts
    window.gtag('event', 'purchase', props);
  },
  trackGoogleEvent: (eventName, context) => {
    if (!isInitialized()) return;
    // @ts-expect-error gtag does exist and is set in globals.d.ts
    window.gtag('event', eventName, context);
  },
} as GoogleSource<GoogleTagManagerConfig>;
