import {
  AmplitudeEvents,
  type AnalyticsContext,
  type RouteChangeTrackerOptions,
  type RouteChangeTrackerProps,
  type UserEventFunction,
  type UserEventTrackerProps,
} from '../../types';

const EVENT_NAME_ATTRIBUTE = 'data-event';
const EVENT_CONTEXT_PREFIX = 'data-event-';
const IMPRESSION_NAME_ATTRIBUTE = 'data-impress';

let trackerFunction: UserEventFunction = () => {};
let previousUrl: URL | undefined;
let previousHref: string;
let previousMilestoneIdx: number;
let reachedBottom: boolean;

const whoopAnalyticsClickTracker = (event: MouseEvent) => {
  let node = event.target;
  while (node) {
    if (node instanceof HTMLElement) {
      if (node.attributes.getNamedItem(EVENT_NAME_ATTRIBUTE)) {
        const context: AnalyticsContext = {};

        // eslint-disable-next-line @typescript-eslint/prefer-for-of
        for (let index = 0; index < node.attributes.length; index += 1) {
          const attribute = node.attributes[index].name;
          if (attribute.startsWith(EVENT_CONTEXT_PREFIX)) {
            const key = attribute.slice(EVENT_CONTEXT_PREFIX.length);
            context[key] = node.attributes[index].value;
          }
        }

        if (previousUrl) {
          context.location = `${previousUrl.pathname}${previousUrl.search}`;
          context.pathname = previousUrl.pathname;
        }

        trackerFunction(
          node.attributes.getNamedItem(EVENT_NAME_ATTRIBUTE)?.value || '',
          context,
          event,
        );

        return;
      }
      node = node.parentNode;
    } else {
      return;
    }
  }
};

const MILESTONES = [1, 25, 50, 75, 100];
export const whoopAnalyticsScrollTracker = (
  trackingFunction: (
    event: AmplitudeEvents,
    context?: Record<string, string | number>,
  ) => void,
) => {
  const currHref = window.location.href;
  if (currHref !== previousHref) {
    previousMilestoneIdx = 0;
    reachedBottom = false;
  }
  previousHref = currHref;

  const docElement = document.documentElement;
  const scrollPercent = Math.round(
    (docElement.scrollTop /
      (docElement.scrollHeight - docElement.clientHeight)) *
      100,
  );

  if (scrollPercent >= MILESTONES[previousMilestoneIdx] && !reachedBottom) {
    const milestone = MILESTONES[previousMilestoneIdx];

    const context: Record<string, string | number> = {
      percent: milestone,
    };
    trackingFunction(AmplitudeEvents.Scrolled, context);

    reachedBottom = previousMilestoneIdx === MILESTONES.length - 1;

    while (previousMilestoneIdx < MILESTONES.length - 1) {
      // in case scrollPercent is > 100
      previousMilestoneIdx += 1;
      if (scrollPercent < MILESTONES[previousMilestoneIdx]) break;
    }
  }
};

const whoopAnalyticsOnRouteChange = (eventName: string, url: URL) => {
  const context: Record<string, string> = {};
  context.location = `${url.pathname}${url.search}`;
  context.pathname = url.pathname;

  if (url.search) {
    const urlParams = new URLSearchParams(url.search);
    urlParams.forEach((value, key) => {
      if (key !== 'token') {
        context[key] = value;
      }
    });
  }
  trackerFunction(eventName, context, {
    type: 'routechange',
  });
};

const whoopAnalyticsRegisterImpressions = (
  options: RouteChangeTrackerOptions = {
    root: null,
    rootMargin: '0px',
    threshold: 0.5,
  },
) => {
  if ('IntersectionObserver' in window) {
    const impressionsObserver = new IntersectionObserver((entries) => {
      entries.forEach((entry) => {
        if (entry.isIntersecting) {
          const context: AnalyticsContext = {};
          const element = entry.target;
          // eslint-disable-next-line @typescript-eslint/prefer-for-of
          for (let index = 0; index < element.attributes.length; index += 1) {
            const attribute = element.attributes[index].name;
            if (attribute.startsWith(EVENT_CONTEXT_PREFIX)) {
              const key = attribute.slice(EVENT_CONTEXT_PREFIX.length);
              context[key] = element.attributes[index].value;
            }
            if (attribute.startsWith(IMPRESSION_NAME_ATTRIBUTE)) {
              context.data_impress = element.attributes[index].value;
            }
          }

          const dataImpressValue = context.data_impress;
          delete context.data_impress;

          if (window.innerWidth >= 1008) {
            context.window_size = 'desktop';
          } else {
            context.window_size = 'mobile';
          }

          // Note: dataImpressValue will always be defined, because the observer is only set up to observe elements
          // that have an IMPRESSION_NAME_ATTRIBUTE attribute. See the query selector below.
          // eslint-disable-next-line @typescript-eslint/restrict-template-expressions
          trackerFunction(`${dataImpressValue}`, context, {
            type: 'impression',
          });

          impressionsObserver.unobserve(element);
        }
      });
    }, options);
    const elements = [].slice.call(
      document.querySelectorAll(`[${IMPRESSION_NAME_ATTRIBUTE}]`),
    );
    elements.forEach((element) => {
      impressionsObserver.observe(element);
    });
  } else {
    trackerFunction('Impressions not supported', undefined, {
      type: 'impression',
    });
  }
};
export default {
  registerUserEventTracker: (props: UserEventTrackerProps) => {
    if (typeof window !== 'undefined') {
      if (typeof props.callbackFn === 'function') {
        trackerFunction = props.callbackFn;
      } else {
        trackerFunction = () => {};
      }

      window.removeEventListener('click', whoopAnalyticsClickTracker);
      if (props.trackClicks) {
        window.addEventListener('click', whoopAnalyticsClickTracker);
      }
    }
  },
  notifyRouteChange: (props: RouteChangeTrackerProps) => {
    if (typeof window !== 'undefined') {
      const location = window.location.href;
      const url = new URL(location);

      if (previousUrl?.pathname !== url.pathname) {
        whoopAnalyticsOnRouteChange(props.eventName, url);
        previousUrl = url;
      }
      whoopAnalyticsRegisterImpressions(props.options);
    }
  },
};
