/* eslint-disable eqeqeq */
/* eslint-disable prefer-rest-params */
/* eslint-disable no-undef */
/* eslint-disable func-names */
/* eslint-disable id-length */
// SOURCE: https://developers.google.com/tag-platform/gtagjs/install
export default function (TAG_ID) {
  if (typeof window === 'undefined' || document == 'undefined') {
    console.error('Cannot initialize GTM: window or document is not defined');
    return;
  }

  (function (w, d, s, l, i) {
    w[l] = w[l] || [];
    w[l].push({ 'gtm.start': new Date().getTime(), event: 'gtm.js' });
    const f = d.getElementsByTagName(s)[0],
      j = d.createElement(s),
      dl = l != 'dataLayer' ? `&l=${l}` : '';
    j.async = true;
    j.src = `https://www.googletagmanager.com/gtm.js?id=${i}${dl}`;
    f.parentNode.insertBefore(j, f);
  })(window, document, 'script', 'dataLayer', TAG_ID);

  window.dataLayer = window.dataLayer || [];
  function gtag() {
    dataLayer.push(arguments);
  }
  window.gtag = gtag;
  window.gtag('js', new Date());

  window.gtag('config', TAG_ID);
}
