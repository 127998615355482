import * as Cookie from 'es-cookie';

const TEN_YEARS_IN_DAYS = 3652;

export const setCookie = <T>(
  name: string,
  value: T,
  expirationDays: number = TEN_YEARS_IN_DAYS,
): void => {
  const cookieAttributes: Cookie.CookieAttributes = {
    expires: expirationDays,
  };

  if (window.location.protocol === 'https:') {
    cookieAttributes.secure = true;
  }

  Cookie.set(name, JSON.stringify(value), cookieAttributes);
};

export const getCookie = <T>(name: string): T | null => {
  const value = Cookie.get(name);

  if (typeof value === 'undefined') {
    return null;
  }

  return JSON.parse(value);
};

export const deleteCookie = (name: string): void => {
  Cookie.remove(name);
};
