import type { AnalyticsSource } from '../../types';
import { isDatadogSyntheticTest } from '../datadog';
import initSegment from './initSegment.script';

export interface SegmentProps {
  key: string;
}
const isInitialized = (): boolean =>
  // Error only exists on build
  window.analytics && !isDatadogSyntheticTest();

export default {
  initialize: (props) => {
    initSegment(props.key);
  },
  identify: (user) => {
    if (!isInitialized()) return;
    const { id, ...userProps } = user;
    window.analytics.identify(id, userProps);
  },
  trackEvent: (eventName, context) => {
    if (!isInitialized()) return;
    window.analytics.track(eventName, context);
  },
} as AnalyticsSource<SegmentProps>;
